













































































































































































































import { Component, Vue } from 'vue-property-decorator';
import debounce from 'lodash/debounce';
import camelcaseKeys from 'camelcase-keys';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfYear from 'date-fns/startOfYear';
import endOfYear from 'date-fns/endOfYear';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import Decimal from 'decimal.js';

import { constants } from '@/utils/constants';

import { toasts } from '@/utils/toasts';

import {
  getAvailableInventories,
} from '../../goods-received-notes/api/goods-received-notes-api';

import {
  getInventoryDocumentsSlipReportData,
} from '../api/inventory-reports-api';

@Component({
  name: 'InventoryJournal',
})
export default class extends Vue {
  allInventoryOptions: Array<any> = [];
  inventoryOptions: Array<any> = [];
  isInventoryOptionsLoading = false;
  documentType: any|null = null;

  documentTypeConstants = constants;

  reportLoading = false;

  get Decimal() {
    return Decimal;
  }

  formatDate(val, fmt) {
    return format(val, fmt);
  }

  tdClassProvider(val) {
    const retVal = ['text-right'];
    if (new Decimal(val).lt(0)) {
      retVal.push('table-warning');
    }
    return retVal;
  }

  documentTypeFormatter(val) {
    switch (val) {
      case 'TRA': return 'transfer';
      case 'FAC': return 'fact. emisă';
      case 'AVZ': return 'aviz emis';
      case 'FAF': return 'intrare (fact.)';
      case 'GRN': return 'intrare NIR';
      case 'NIR': return 'intrare NIR';
      case 'NP.': return 'notă de predare';
      case 'MRN': return 'consum';
      case 'MTN': return 'Transfer';
      case 'BCC': return 'bon consum';
      case 'BCO': return 'Consum ob. inv.';
      default: return val;
    }
  }

  dateInterval = [startOfMonth(new Date()), endOfMonth(new Date())];
  datepickerShortcuts = [{
    text: 'Astăzi',
    onClick: () => [startOfDay(new Date()), endOfDay(new Date())],
  },
  {
    text: 'Ieri',
    onClick: () => [addDays(startOfDay(new Date()), -1), addDays(endOfDay(new Date()), -1)],
  },
  {
    text: 'Luna curentă',
    onClick: () => [startOfMonth(new Date()), endOfMonth(new Date())],
  }, {
    text: 'Luna trecută',
    onClick: () => [addMonths(startOfMonth(new Date()), -1), endOfMonth(addMonths(new Date(), -1))],
  }, {
    text: 'Ultimele 3 luni',
    onClick: () => [addMonths(startOfMonth(new Date()), -2), endOfMonth(new Date())],
  }, {
    text: 'Ultimele 6 luni',
    onClick: () => [addMonths(startOfMonth(new Date()), -5), endOfMonth(new Date())],
  }, {
    text: 'Anul curent',
    onClick: () => [startOfYear(new Date()), endOfYear(new Date())],
  }, {
    text: 'Anul trecut',
    onClick: () => [addYears(startOfYear(new Date()), -1), addYears(endOfYear(new Date()), -1)],
  }];

  documentTypes = [
    { code: this.documentTypeConstants.GRN, label: 'NIR' },
    { code: this.documentTypeConstants.MRN, label: 'Bonuri de consum' },
    { code: this.documentTypeConstants.MTN, label: 'Bonuri de transfer' },
    { code: this.documentTypeConstants.BCO, label: 'Bon consum ob. inv.' },
    { code: this.documentTypeConstants.BTO, label: 'Bon transfer ob. inv.' },
  ]

  startDate = startOfMonth(new Date());
  endDate = endOfMonth(new Date());
  inventory: any|null = null;
  excludeNoEntries = false;

  items: Array<any> = [];

  async asyncSearchInventory() {
    if (this.allInventoryOptions?.length) {
      this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);
      return Promise.resolve();
    }

    this.isInventoryOptionsLoading = true;

    this.allInventoryOptions = camelcaseKeys(await getAvailableInventories());
    this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);

    console.log(this.inventoryOptions, this.allInventoryOptions);

    this.isInventoryOptionsLoading = false;
    return Promise.resolve();
  }

  async localSearchInventory(query) {
    this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated
    && (o.label.toLowerCase().indexOf(query.toLowerCase()) !== -1 || o.description.toLowerCase().indexOf(query.toLowerCase()) !== -1));
  }

  debouncedSearchInventory = debounce(this.asyncSearchInventory, 75, { maxWait: 150 });

  onReportIntervalChange(val) {
    [this.startDate, this.endDate] = val;
    this.dateInterval = val;
  }

  async onDownloadPdf() {
    if (!this.inventory) {
      toasts.error('Trebuie aleasă o gestiune!');
      return;
    }
    const docType = this.documentType?.code ? this.documentType?.code : '';
    window.location.href = `/api/inventory-report/inventory-documents-slip-pdf?startDate=${encodeURIComponent(this.startDate.toISOString())}&endDate=${encodeURIComponent(this.endDate.toISOString())}&inventoryId=${this.inventory.inventoryId}&documentTypeCode=${docType}`;
  }

  async onLoadReportData() {
    if (!this.inventory) {
      toasts.error('Trebuie aleasă o gestiune!');
      return;
    }

    try {
      this.reportLoading = true;
      const reportData = await getInventoryDocumentsSlipReportData(
        formatISO(this.startDate),
        formatISO(this.endDate),
        this.inventory?.inventoryId,
        this.documentType?.code,
      );

      this.items = reportData;
    } finally {
      this.reportLoading = false;
    }
  }

  async created() {
    this.allInventoryOptions = camelcaseKeys(await getAvailableInventories());
    this.inventoryOptions = this.allInventoryOptions.filter((o) => o.isUserAssociated);
  }
}
