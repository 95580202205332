import httpClient from '@/services/http-client';

const documentLogPrefix = '/api/document-log-entry';
const lookupRoutePrefix = '/api/lookup';
const grnRoutePrefix = '/api/grn';

export async function lookupSupplier(lookupTerm) {
  const response = await httpClient.get(`${lookupRoutePrefix}/partner`, {
    params: {
      lookupTerm,
    },
  });

  return response.data;
}

export async function lookupSupplierById(supplierId) {
  const response = await httpClient.get(`${lookupRoutePrefix}/partner-by-id`, {
    params: {
      partnerId: supplierId,
    },
  });

  return response.data;
}

export async function lookupInventory(lookupTerm) {
  const response = await httpClient.get(`${lookupRoutePrefix}/inventory`, {
    params: {
      lookupTerm,
    },
  });

  return response.data;
}

export async function lookupProduct(lookupTerm) {
  const response = await httpClient.get(`${lookupRoutePrefix}/product`, {
    params: {
      lookupTerm,
    },
  });

  return response.data;
}

export async function lookupEmployee(lookupTerm) {
  const response = await httpClient.get(`${lookupRoutePrefix}/employee`, {
    params: {
      lookupTerm,
    },
  });
  return response.data;
}

export async function getDocumentLogs(documentId) {
  const response = await httpClient.get(`${documentLogPrefix}/grn/${documentId}`);
  return response.data;
}

export async function getAvailableStock({
  lookupDate, productId, inventoryId, unitPrice,
}) {
  const response = await httpClient.get(`${lookupRoutePrefix}/stock`, {
    params: {
      lookupDate,
      productId,
      inventoryId,
      unitPrice,
    },
  });

  return response.data;
}

export async function getAvailableInventories() {
  const response = await httpClient.get(`${grnRoutePrefix}/inventories`);

  return response.data;
}

export async function getInventory(inventoryId) {
  const response = await httpClient.get(`${grnRoutePrefix}/inventory`, {
    params: {
      inventoryId,
    },
  });

  return response.data;
}

export async function getAvailableSeries(seriesYear) {
  const response = await httpClient.get(`${grnRoutePrefix}/series`, {
    params: {
      seriesYear,
    },
  });

  return response.data;
}

export async function getGoodsReceivedNotesList(pageNumber, perPage, filters) {
  const response = await httpClient.get(`${grnRoutePrefix}/list`, {
    params: {
      pageNumber: pageNumber || 1,
      perPage: perPage || 25,
      filters: JSON.stringify(filters),
    },
  });

  return response.data;
}

export async function saveDraft(draft) {
  const response = await httpClient.post(`${grnRoutePrefix}/draft`, draft);

  return response.data;
}

export async function deleteDraft(id) {
  const response = await httpClient.delete(`${grnRoutePrefix}/draft/${id}`);

  return response.data;
}

export async function deleteCanceled(id) {
  const response = await httpClient.delete(`${grnRoutePrefix}/canceled/${id}`);

  return response.data;
}

export async function cancelDocument(id) {
  const response = await httpClient.post(`${grnRoutePrefix}/cancel/${id}`);

  return response.data;
}

export async function restoreDocument(id) {
  const response = await httpClient.post(`${grnRoutePrefix}/restore/${id}`);

  return response.data;
}

export async function postDraft(id) {
  const response = await httpClient.post(`${grnRoutePrefix}/post/${id}`);

  return response.data;
}

export async function getDetails(id) {
  const response = await httpClient.get(`${grnRoutePrefix}/details/${id}`);

  return response.data;
}

export async function compareLatestSeries(id) {
  const response = await httpClient.get(`${grnRoutePrefix}/compare-latest-series/${id}`);

  return response.data;
}

export async function getPdf(id, numCopies) {
  const response = await httpClient.get(`${grnRoutePrefix}/pdf/${id}`, {
    params: {
      copies: numCopies || 1,
      cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
    },
    // responseType: 'blob',
    responseType: 'arraybuffer',
    // This is in milliseconds, so wait up to 15 seconds
    timeout: 15 * 1000,
    headers: {
      Accept: 'application/octet-stream',
    },
  });

  return response.data;
}

export async function updateDocumentFooter(id, data) {
  const response = await httpClient.patch(`${grnRoutePrefix}/footer/${id}`, data);

  return response.data;
}

export async function addPublishedLine(id, line) {
  const response = await httpClient.post(`${grnRoutePrefix}/line/${id}`, line);

  return response.data;
}

export async function deletePublishedLine(id, lineId) {
  const response = await httpClient.delete(`${grnRoutePrefix}/line/${id}/${lineId}`);

  return response.data;
}

export async function getAvailableAccounts() {
  const response = await httpClient.get('/api/adm/product-classification/accounts?includeClass4=true');
  return response.data;
}

export async function getAccountingInvoice(id) {
  const response = await httpClient.get(`${grnRoutePrefix}/accounting-invoice/${id}`);

  return response.data;
}

export async function syncAccounting(id, data) {
  const response = await httpClient.post(`${grnRoutePrefix}/sync/${id}`, data);

  return response.data;
}

export async function unsyncAccounting(id) {
  const response = await httpClient.delete(`${grnRoutePrefix}/sync/${id}`);

  return response.data;
}

export async function getAccountingSyncData(id) {
  const response = await httpClient.get(`${grnRoutePrefix}/sync/${id}`);

  return response.data;
}

export async function editSourceDocumentDetails(id, data) {
  const response = await httpClient.patch(`${grnRoutePrefix}/source-document/${id}`, data);

  return response.data;
}
